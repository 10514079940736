import routeList from '@/router/labels'

const officePages = (vm, office, userChat) => [
  {
    id: 'offer',
    officeActiveKey: 'activeOffers',
    showDot: {
      alias: 'offer',
      value: userChat.offer,
    },
    label: vm.$t('header.navigational.offer.main'),
    link: {
      name: routeList.OFFICE_OFFER_LIST_NAME,
      params: { id: office || null, },
    }, // param in user office
    children: [
      {
        label: vm.$t('header.navigational.offer.active'),
        counter: 'activeOffers',
        counterSource: 'office',
        link: {
          name: routeList.OFFICE_OFFER_LIST_NAME,
          params: { id: office || null, },
          query: { statusGroup: 'active' },
        },
        isQueryRoute: true
      },
      {
        label: vm.$t('header.navigational.offer.inactive'),
        counter: 0,
        link: {
          name: routeList.OFFICE_OFFER_LIST_NAME,
          params: { id: office || null, },
          query: { statusGroup: 'inactive' },
        },
        isQueryRoute: true
      }
    ]
  },
]

const shiftPages = (vm, office, userChat) => [
  {
    id: 'shift',
    officeActiveKey: 'activeRelays',
    showDot: {
      alias: 'shift',
      value: userChat.relay,
    },
    label: vm.$t('header.navigational.shift.main'),
    link: {
      name: routeList.OFFICE_SHIFT_LIST_NAME,
      params: { id: office || null, },
    }, // param in user office
    children: [
      {
        label: vm.$t('header.navigational.shift.future'),
        counter: 0,
        link: {
          name: routeList.OFFICE_SHIFT_LIST_NAME,
          query: { statusGroup: 'accepted' },
          params: { id: office || null, }
        },
        isQueryRoute: true
      },
      {
        label: vm.$t('header.navigational.shift.active'),
        counter: 0,
        link: {
          name: routeList.OFFICE_SHIFT_LIST_NAME,
          query: { statusGroup: 'active' },
          params: { id: office || null, }
        },
        isQueryRoute: true
      },
      {
        label: vm.$t('header.navigational.shift.inactive'),
        counter: 0,
        link: {
          name: routeList.OFFICE_SHIFT_LIST_NAME,
          query: { statusGroup: 'inactive' },
          params: { id: office || null, }

        },
        isQueryRoute: true
      }
    ]
  },
]
const workerPage = (vm, office) => [
  {
    id: 'worker',
    label: 'Мои работники',
    link: {
      name: routeList.WORKER_ROUTE_NAME,
      params: { id: office || null, },
    }, // param in user office
    children: [
    ]
  },
]

const infoPages = (vm, office) => [
  {
    id: 'information',
    label: vm.$t('header.navigational.info.main'),
    route: vm.$t('header.navigational.info.main'),
    counter: 0,
    link: {
      name: '',
      params: '',
    },
    children: [
      {
        label: vm.$t('header.navigational.info.payments'),
        counter: 0,
        link: { name: routeList.OFFICE_PAYMENT_LIST_NAME, params: { id: office || null } },
        isActive: true,
        requiredOffice: false,
        rootOnly: false,
      },
      {
        label: vm.$t('header.navigational.info.incidents'),
        counter: 0,
        link: { name: routeList.OFFICE_INCIDENT_LIST_NAME, params: { id: office || null } },
        requiredOffice: false,
        rootOnly: false,
      },
      {
        label: vm.$t('header.navigational.info.company_profile'),
        counter: 0,
        link: { name: routeList.OFFICE_COMPANY_PROFILE_NAME },
        requiredOffice: false,
        rootOnly: true,
      },
      {
        label: vm.$t('header.navigational.info.documents'),
        counter: 0,
        link: { name: routeList.OFFICE_DOCUMENTS_NAME },
        requiredOffice: false,
        rootOnly: true,
      },
      {
        label: vm.$t('header.navigational.info.stats'),
        counter: 0,
        link: { name: routeList.OFFICE_STATS_NAME, params: { id: office || null } },
        requiredOffice: false,
        rootOnly: false,
      },
    ]
  },
]

const vacancyPages = (vm, office) => [
  {
    id: 'vacancy',
    label: vm.$t('header.navigational.vacancy.main'),
    link: {
      name: routeList.VACANCIES_LIST_NAME,
    }, // param in user office
    children: []
  }]

const organizationRootPanel = [
  {
    id: 'management',
    label: 'Управление',
    link: '',
    children: [
      {
        label: 'Вакансии',
        counter: 0,
        link: { name: routeList.VACANCIES_LIST_NAME },
        children: []
      },
      {
        label: 'Объекты',
        counter: 0,
        link: { name: routeList.OFFICE_LIST_ROUTE_NAME },
      },
      {
        label: 'Пользователи',
        counter: 0,
        link: { name: routeList.USER_LIST_NAME },
        children: []
      },
    ]
  },
]
const organizationAdminPanel = [
  {
    id: 'management',
    label: 'Управление',
    link: '',
    children: [
      {
        label: 'Вакансии',
        counter: 0,
        link: { name: routeList.VACANCIES_LIST_NAME },
        children: []
      },
      {
        label: 'Объекты',
        counter: 0,
        link: { name: routeList.OFFICE_LIST_ROUTE_NAME },
      },
      {
        label: 'Пользователи',
        counter: 0,
        link: { name: routeList.USER_LIST_NAME },
        children: []
      },
    ]
  },
]


const managerPanel = (translator, office) => [
  ...officePages(translator, office),
  ...shiftPages(translator, office),
  ...workerPage(translator, office),
  ...infoPages(translator, office),
  ...vacancyPages(translator, office),
]


export {
  workerPage,
  organizationAdminPanel, organizationRootPanel, managerPanel, officePages, infoPages, shiftPages, vacancyPages
}
