<template>
  <div
    class="header__navigation-item"
    @click.stop="clickByNav(navigation)"
  >
    <div
      v-if="navigation.showDot && navigation.showDot.value && parseInt(navigation.showDot.value) > 0"
      class="dot"
    >
      {{ navigation.showDot.value }}
    </div>
    <router-link
      :to="navigation.link"
      class="nav-item__title"
      :active-class="getActiveStatus"
      tag="h3"
    >
      <span :class="{'parent-activation': getActivationCounter(navigation.officeActiveKey) > 0}">
        {{ navigation.label }}
      </span>
    </router-link>

    <svg
      v-if="navigation.children.length"
      class="nav-item__drop-icon"
      :class="{'isOpen': isOpen}"
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 9L5.69535e-07 2.48528L2 0.499999L7 5.02944L12 0.5L14 2.48528L7 9Z"
        fill="black"
      />
    </svg>

    <ul
      v-if="navigation.children.length && currentId === navigation.id && isOpen"
      class="nav-item__children"
    >
      <template v-for="dropEl in navigation.children">
        <router-link
          v-if="dropEl.isQueryRoute"
          :key="dropEl.label"
          :to=" dropEl.link"
          class="nav-item__children__el"
          :class="{
            'nav-item__children__el_active': $route.name === dropEl.link.name
              && $route.query.statusGroup === dropEl.link.query.statusGroup
          }"
          tag="li"
          @click="close(false)"
        >
          {{ dropEl.label }}
          {{ count(dropEl) }}
        </router-link>
        <router-link
          v-else
          :key="dropEl.label"
          :to=" dropEl.link"
          class="nav-item__children__el"
          active-class="nav-item__children__el_active"
          tag="li"
          @click="close(false)"
        >
          {{ dropEl.label }}
          {{ count(dropEl) }}
        </router-link>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    currentId: {
      type: String
    },
    office: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    getActiveStatus () {
      const name = this.$route.name
      const currentName = this.navigation.link.name
      const activeClass = 'nav-item__title'
      const isCurrentRoute = (currentName && name === currentName)

      return this.currentId === this.navigation.id || !!isCurrentRoute ? `${activeClass}_active` : activeClass
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
  },
  watch: {
    isOpen (val) {
      if (val) {
        document.addEventListener('click', this.close)
      } else {
        document.removeEventListener('click', this.close)
      }
    }
  },
  methods: {
    getActivationCounter (key) {
      if (!this.currentOffice || !key) {
        return 0
      }
      return parseInt(this.currentOffice[key])
    },
    count (dropEl) {
      if (dropEl.counter && dropEl.counterSource && dropEl.counterSource === 'office') {
        return this.$store.state.office.userOffice ? `(${this.$store.state.office.userOffice[dropEl.counter]})` : ''
      }

      return this.user && dropEl.counter ? `(${this.user[dropEl.counter]})` : ''
    },
    clickByNav (event) {
      this.$emit('onMenuSelected', this.navigation.id)
      this.isOpen = !this.isOpen
      if (event instanceof Function) event()
    },
    close (emit = true) {
      if (emit) {
        this.$emit('onMenuSelected', null)
      }
      this.isOpen = false
    }
  },
}
</script>

<style lang="sass" scoped>
  .parent-activation
    color: #8BD118
  .dot
    display: flex
    justify-content: center
    align-items: center
    width: 12px
    height: 13px
    background: #8BD118
    position: absolute
    border-radius: 50%
    right: -9px
    top: -7px
    font-size: 9px
    text-align: center
    color: white
  .header__navigation-item
    margin-left: 50px
    display: flex
    align-items: center
    position: relative
    cursor: pointer

  .nav-item
    position: relative
    &__title
      @include fontStyle(800, 16px)
      color: rgba(0, 0, 0, 0.3)
      &_active
        color: rgb(0, 0, 0)
    &__drop-icon
      margin-left: 4px
      transition: all .5s
    &__children
      position: absolute
      z-index: 1
      min-width: 100%
      top: 100%
      left: 0
      background: #F0F0F1
      border: 1.5px solid rgba(0, 0, 0, 0.1)
      box-shadow: 0px 10px 15px rgba(117, 117, 117, 0.1)
      border-radius: 6px
      padding: 10px
      &__el
        @extend %navigation__children-item
        color: rgba(0, 0, 0, 0.51)
        padding: 5px
        margin-bottom: 10px
        &:last-child
          margin-bottom: 0
        &_active
          @extend %navigation__children-item
          color: rgb(0, 0, 0)

  .isOpen
    transform: rotate(180deg)
</style>
