<template>
  <div
    id="main-container"
    ref="mainContainer"
    style="flex: 1;"
  >
    <slot name="header">
      <header-navigation
        ref="header"
      >
        <!-- @isScroll="computedMarginCenter" -->
        <template #subheader>
          <slot name="subheader" />
        </template>
      </header-navigation>
    </slot>
    <div
      class="wb-container"
    >
      <slot name="centerTop" />
      <div
        ref="center"
        style="border: 2px solid transparent"
        class="main-center"
        :class="centerClass"
      >
        <slot name="center" />
      </div>

      <div
        v-show="showModal"
        class="wb-container-modal"
      >
        <!-- @click.prevent.self="$emit('closeModal')" -->
        <slot name="modal" />
      </div>
    </div>
    <WbdChatComponent
      v-if="showChat"
      :customStyle="chatMargin"
      :participants="[
        {
          id: 'user1',
          name: 'Matteo',
        },
        {
          id: 'user2',
          name: 'Support',
        }
      ]"
      :ticket="chatOptions.ticket"
      :messageList="getCurrentTicketMessageList"
      @sendMessage="chatOptions.sendMessage"
      @chatClose="chatClose"
    />
  </div>
</template>

<script>
import HeaderNavigation from '@/components/TheHeaderNavigation'
import WbdChatComponent from '@/components/chat/index'

export default {
  name: 'AppMainContainer',
  components: { HeaderNavigation, WbdChatComponent },
  props: {
    centerClass: {
      type: [String, Object, Array]
    },
    headerClass: {
      type: [String, Object, Array]
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      centerStyle: null,
      windowHeight: 0,
      windowWith: 0,
      chatMargin: {},
    }
  },
  computed: {
    showChat () {
      return this.$store.state.chat.show
    },
    chatOptions () {
      return this.$store.state.chat.options
    },
    getCurrentTicketMessageList () {
      return this.$store.state.chat.ticketMessageList
    }
  },
  watch: {
    showChat (newHeight, oldHeight) {
      this.onResize()
    }
  },
  mounted () {
    this.setCenterMargin()
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },
  created () {
  },
  methods: {

    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    },

    onResize () {
      this.windowHeight = window.innerHeight
      this.windowWith = window.innerWidth
      if (this.$refs.center) {
        const centerStyleWith = parseInt(
          window.getComputedStyle(this.$refs.center).getPropertyValue('width'),
          10
        )
        // const centerStyleHeight = parseInt(
        //   window.getComputedStyle(this.$refs.center).getPropertyValue('height'),
        //   10
        // )
        const mainContainerStyleHeight = parseInt(
          window.getComputedStyle(this.$refs.mainContainer).getPropertyValue('height'),
          10
        )
        const headerStyleHeight = parseInt(
          window.getComputedStyle(this.$refs.header.$el).getPropertyValue('height'),
          10
        )
        this.chatMargin = {
          right: `${this.windowWith >= 1024 ? (this.windowWith - centerStyleWith) / 2 : 0}px`,
          width: `${centerStyleWith * 0.45}px`,
          bottom: `30px`,
          height: `${(mainContainerStyleHeight - headerStyleHeight)}px`,
          // paddingBottom: `70px`,
        }
        this.$store.commit('chat/style', this.chatMargin)
      }
    },
    // computedMarginCenter (val) {
    //   if (val) {
    //     this.$refs.center.style.marginTop = `${parseInt(this.centerStyle) + this.$refs.header.$el.offsetHeight}px`
    //   } else {
    //     this.$refs.center.style.marginTop = `${this.centerStyle}`
    //   }
    //   this.$emit('isScroll', val)
    // },
    chatClose () {
      this.$store.commit('chat/show', false)
      this.$store.commit('chat/options', { })
      this.eventsBus.$emit('chatClose')
    },
    setCenterMargin () {
      if (this.$refs.center) {
        this.centerStyle = window.getComputedStyle(this.$refs.center).getPropertyValue('margin-top')
      } else {
        setTimeout(() => {this.setCenterMargin()}, 200)
      }
    }
  }
}
</script>


<style lang="sass" scoped>
  .wb-container
    /*position: relative*/
    display: flex
    flex-grow: 1
    flex-direction: column
    margin: 0 auto
    //show for responsible
    //min-width: 360px
    //max-width: 1024px
    //overflow: hidden
    //hide  for responsible
    min-width: 940px
    max-width: 1024px
    width: 95%
    //height: calc(100vh - #{$footer-height} - #{$footer-margin-top})

  .main-center
    padding-bottom: 0
    // height: calc(100vh - #{$footer-height} - #{$footer-margin-top} - #{$header-height})

  .user-card
    display: flex
    margin-top: 20px
    flex-direction: column
    flex-grow: 1

  .offer
    margin-top: 5px

  .offer-item
    display: flex
    flex-grow: 1
</style>

