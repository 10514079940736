<template>
  <div>
    <beautiful-chat
      :participants="participants"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :placeholder="'Пишите сообщение'"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :containerStyle="customStyle"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage"
    >
      <template v-slot:header>
        <div style="text-align: center;align-self: center;flex: 1;">
          <h4>
            {{ ticket.topic.title }}
          </h4>
          <p class="date inner">
            {{ `${ticket.number || ''}  ${ticket.createdDate}` }} <b>{{ ticket.createdTime }}</b>
          </p>
        </div>
      </template>
    </beautiful-chat>
  </div>
</template>
<script>
import CloseIcon from '@/plugins/vue-beautiful-chat/src/assets/close-icon.png'
import OpenIcon from '@/plugins/vue-beautiful-chat/src/assets/logo-no-bg.svg'
import FileIcon from '@/plugins/vue-beautiful-chat/src/assets/file.svg'
import CloseIconSvg from '@/plugins/vue-beautiful-chat/src/assets/close.svg'

export default {
  name: 'WbdChatComponent',
  props: {
    messageList: {
      type: Array
    },
    participants: {
      type: Array
    },
    ticket: {
      type: Object,
      required: true,
    },
    customStyle: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      icons: {
        open: {
          img: OpenIcon,
          name: 'default',
        },
        close: {
          img: CloseIcon,
          name: 'default',
        },
        file: {
          img: FileIcon,
          name: 'default',
        },
        closeSvg: {
          img: CloseIconSvg,
          name: 'default',
        },
      },
      newMessagesCount: 0,
      isChatOpen: true,
      showTypingIndicator: '',
      colors: {
        header: {
          bg: '#8bd118',
          text: '#ffffff'
        },
        launcher: {
          bg: '#8bd118'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        },
        customStyle: this.customStyle
      },
      // specifies the color scheme for the component
      alwaysScrollToBottom: false,
      messageStyling: true
    }
  },
  created () {
    this.eventsBus.$on(this.eventNames.CLOSE_CHAT, this.closeChat)
  },
  methods: {
    sendMessage (text) {
      if (text && text.trim() !== '') {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    onMessageWasSent (message) {
      this.$emit('sendMessage', message)
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
      this.$emit('chatClose')
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
    },
    editMessage (message) {
      const m = this.messageList.find((m) => m.id === message.id)
      m.isEdited = true
      m.data.text = message.data.text
    }
  }
}
</script>
<style type="text/scss" scoped>
  .date {
    font-size: 12px;
    line-height: 15px;
    opacity: 0.3;
    color: #000000;
  }
  .inner {
    display: inline-block;
    padding-right: 10px;
    margin-right: 10px;
  }
</style>

