<template>
  <svg :width="width ? width : 13" viewBox="0 0 410 460" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M334.3 297.043C316.096 322.045 286.608 338.329 253.384 338.329H206.766C190.662 338.329 176.948 328.105 171.755 313.795C165.345 311.883 159.059 309.401 152.925 306.353C139.935 299.899 128.14 291.155 117.757 280.323C50.407 295.119 0 355.131 0 426.926V436.31C0 449.21 10.458 459.668 23.358 459.668H385.761C398.661 459.668 409.119 449.21 409.119 436.31V426.926C409.118 371.464 379.035 323.032 334.3 297.043Z" :fill="active ? activeColor : fill || '#0000004d'"/>
    <path d="M92.931 232.178C102.97 232.178 111.708 226.614 116.235 218.403C116.354 218.728 116.475 219.051 116.597 219.374C116.633 219.471 116.669 219.568 116.705 219.665C127.325 247.619 147.989 271.053 175.237 281.292C181.827 270.821 193.48 263.857 206.767 263.857H253.385C258.035 263.857 262.363 262.545 266.157 260.424C272.529 256.861 278.259 247.822 281.218 243.031C285.953 235.364 289.622 227.243 292.875 218.389C294.703 221.709 297.217 224.597 300.229 226.86V238.291C300.229 264.121 279.215 285.136 253.384 285.136H206.766C197.953 285.136 190.808 292.281 190.808 301.094C190.808 309.908 197.953 317.052 206.766 317.052H253.384C296.813 317.052 332.145 281.72 332.145 238.291V226.86C338.605 222.007 342.784 214.283 342.784 205.582V157.463V139.011C342.784 130.131 338.429 122.274 331.742 117.443C326.556 51.816 271.496 0 204.559 0C137.621 0 82.562 51.816 77.376 117.442C70.689 122.273 66.334 130.131 66.334 139.01V205.58C66.334 220.311 78.301 232.178 92.931 232.178ZM204.559 31.917C254.111 31.917 294.982 69.785 299.759 118.102C296.623 120.569 294.054 123.722 292.284 127.34C277.226 88.054 243.612 60.702 204.558 60.702C164.662 60.702 131.587 88.994 116.891 127.183C116.871 127.235 116.852 127.288 116.832 127.341C115.062 123.723 112.493 120.57 109.357 118.103C114.137 69.785 155.007 31.917 204.559 31.917Z" :fill="active ? activeColor : fill || '#0000004d'"/>
  </svg>


</template>

<script>
    import icon_config from "@/mixins/icon_config";

    export default {
        name: "IconStar",
        mixins: [icon_config],
        props: {
            value: {
                type: Number,
            }
        },

        methods: {
            mouseover() {
                this.$emit('hover', this.value)
            },
            click() {
                this.$emit('click', this.value)
            }
        },
    }
</script>

<style scoped>

</style>
