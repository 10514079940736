var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"mainContainer",staticStyle:{"flex":"1"},attrs:{"id":"main-container"}},[_vm._t("header",function(){return [_c('header-navigation',{ref:"header",scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_vm._t("subheader")]},proxy:true}],null,true)})]}),_c('div',{staticClass:"wb-container"},[_vm._t("centerTop"),_c('div',{ref:"center",staticClass:"main-center",class:_vm.centerClass,staticStyle:{"border":"2px solid transparent"}},[_vm._t("center")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"wb-container-modal"},[_vm._t("modal")],2)],2),(_vm.showChat)?_c('WbdChatComponent',{attrs:{"customStyle":_vm.chatMargin,"participants":[
      {
        id: 'user1',
        name: 'Matteo',
      },
      {
        id: 'user2',
        name: 'Support',
      }
    ],"ticket":_vm.chatOptions.ticket,"messageList":_vm.getCurrentTicketMessageList},on:{"sendMessage":_vm.chatOptions.sendMessage,"chatClose":_vm.chatClose}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }