<template>
  <div
    v-if="isGlobal"
    class="index-list"
    @click="openAnonymousModal()"
  >
    <div
      class="index-list__item"
    >
      <div style="margin: 0; padding: 0">
        <div class="index-list__title">
          {{ 'Активируйте личный кабинет' }}
        </div>
        <div class="index-list__text">
          {{ 'Заполните свой профиль, реквизиты организации и примите условия Оферты' }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="organization && organization.isActive"
    style="margin: 0; padding: 0"
  >
    <h4 style="font-weight: bold; margin-bottom: 12px;text-decoration: underline">
      Для начала работы:
    </h4>

    <div
      v-if="userRole === 'manager'"
      class="index-list"
    >
      <div
        v-for="(item, key) in mainMenu.state.manager.list.filter(menuItem => menuItem.level === 1)"
        :key="key"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="user.office"
          :role="'manager'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
      <h4 style="font-weight: bold; margin-bottom: 12px;text-decoration: underline">
        И вы сможете:
      </h4>
      <div
        v-for="item in mainMenu.state.manager.list.filter(menuItem => menuItem.level !== 1)"
        :key="`2--${item.id}`"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="mainMenu.state.manager.office"
          :role="'administrator'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
    </div>
    <div
      v-else-if="userRole === 'admin'"
      class="index-list"
    >
      <div
        v-for="(item, key) in mainMenu.state.admin.list.filter(menuItem => menuItem.level === 1)"
        :key="key"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="mainMenu.state.administrator.office"
          :role="'administrator'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
      <h4 style="font-weight: bold; margin-bottom: 12px;text-decoration: underline">
        И вы сможете:
      </h4>
      <div
        v-for="item in mainMenu.state.admin.list.filter(menuItem => menuItem.level !== 1)"
        :key="`2--${item.id}`"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="mainMenu.state.admin.office"
          :role="'administrator'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
    </div>
    <div
      v-else-if="userRole === 'root'"
      class="index-list"
    >
      <div
        v-for="item in mainMenu.state.administrator.list.filter(menuItem => menuItem.level === 1)"
        :key="`11--${item.id}`"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="mainMenu.state.administrator.office"
          :role="'administrator'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
      <h4 style="font-weight: bold; margin-bottom: 12px;text-decoration: underline">
        И вы сможете:
      </h4>
      <div
        v-for="item in mainMenu.state.administrator.list.filter(menuItem => menuItem.level !== 1)"
        :key="`2--${item.id}`"
        class="index-list__item"
      >
        <HelpItem
          :model="item"
          :office="mainMenu.state.administrator.office"
          :role="'administrator'"
          @onInitRoutePush="initRoutePush"
        />
      </div>
    </div>
  </div>
  <div v-else />
</template>

<script>
import { mainPageMenuList } from '@/modules/mainMenu'
import userUtil from '@/utils/user-util'
import HelpItem from '@/components/index/help-item'
import { mapState } from 'vuex'

export default {
  name: 'IndexList',
  components: { HelpItem },
  props: {
    isGlobal: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      mainMenu: mainPageMenuList,
      userRole: null
    }
  },
  computed: {
    ...mapState('users', {
      'user': 'info'
    }),
    organization () {
      return this.$store.state.organization.organizationUser
    },
  },
  watch: {
    'user' (val) {
      if (val && val.roleList) {
        if (userUtil.isAdmin(val)) {
          this.userRole = 'admin'
        } else if (userUtil.isManager(val)) {
          this.userRole = 'manager'
        } else if (userUtil.isRoot(val)) {
          this.userRole = 'root'
        }
      }
    }
  },
  methods: {
    openAnonymousModal () {
      this.$store.dispatch('popup/showModal', { key: 'showAnonymousStatusModal' })
    },
    showVideo (step) {
      this.$store.commit('popup/currentStep', step)
      this.$store.dispatch('popup/showModal', { key: 'showVideoModal' })
    },
    setStep (step) {
      this.$store.dispatch('popup/currentStep', step)
    },
    initRoutePush (item, office, role) {
      if (role === 'manager') {
        if (office && office.id) {
          this.$router.push({ name: item.linkName, params: { id: office.id } })
        }
      } else if (role === 'administrator') {
        if (item.id === 4 || item.id === 3) {
          this.$router.push({ name: item.linkName, params: { id: 'office' } })
        } else {
          this.$router.push({ name: item.linkName })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.index-list
  width: 100%
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  &__item
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    min-height: 45px
    background: #FFFFFF
    border: 1.5px solid #E3E3E3
    box-sizing: border-box
    box-shadow: 0 5px 15px rgba(117, 117, 117, 0.1)
    border-radius: 6px
    padding: 8px
    cursor: pointer
    margin-bottom: 16px
  &__title
    font-style: normal
    font-weight: 800
    font-size: 16px
    line-height: 20px
    opacity: 0.9
    color: #8BD118
    text-decoration: underline
    //border-bottom: 1px solid #e5e5e5
  &__text
    font-weight: 500
    font-size: 12px
    line-height: 18px
    color: #666
    margin-top: 8px

</style>
