<template>
  <div
    class="header"
    :class="{'header_scrolled': checkScroll}"
  >
    <div
      v-if="currentUser"
      class="header-container"
    >
      <header class="header__main mb-2">
        <h1 class="logo">
          <router-link
            style="text-decoration: none"
            tag="a"
            :to="{path: '/'}"
          >
            <span class="main-title">wibedo</span>
          </router-link>
        </h1>

        <nav
          v-if="currentUser"
          class="navigation"
        >
          <NavigationItem
            v-for="item in navigationPanel"
            :key="item.name"
            :navigation="item"
            :user="currentUser"
            :currentId="currentId"
            :office="currentOffice"
            @onMenuSelected="updateCurrentId"
          />
        </nav>

        <div class="notifications">
          <router-link
            style="position: relative"
            class="notifications-item"
            :to="supportLink"
          >
            <div
              v-if="!!userChat.support && parseInt(userChat.support, 10) > 0"
              class="dot"
            >
              {{ userChat.support }}
            </div>
            <IconSupport
              :width="20"
              :heigth="21"
              :active="!!userChat.support && parseInt(userChat.support, 10) > 0"
            />
          </router-link>
          <router-link
            class="notifications-item"
            :to="{ name: 'Notifications' }"
          >
            <img
              src="@/assets/notification.png"
              alt=""
            >
          </router-link>

          <figure class="notifications-item user-info__icon">
            <img
              src="@/assets/profile.png"
              alt=""
            >
            <ul
              v-if="currentUser && currentUser.id"
              style="z-index: 999999999 !important;"
              class="user-info__option"
            >
              <li
                v-for="item in profileMenuItemList"
                :key="item.label"
                class="option__item"
                :class="item.class"
                @click="item.event"
              >
                {{ item.label }}
              </li>
            </ul>
          </figure>
        </div>
      </header>
      <IndexList
        v-if="isOrganizationRoot && organization && !organization.isActive"
        :isGlobal="true"
        :style="{'zIndex': getAnonymousZIndex}"
        class="anonymous-banner"
        @click="openAnonymousModal"
      />
      <!--      <div-->
      <!--        v-if="isOrganizationRoot && organization && !organization.isActive"-->
      <!--        :style="{'zIndex': getAnonymousZIndex}"-->
      <!--        class="anonymous-banner"-->
      <!--        @click="openAnonymousModal"-->
      <!--      >-->
      <!--        Заполните ваш профиль, чтобы полноценно пользоваться системой!-->
      <!--      </div>-->
      <div
        :style="{'zIndex': getAnonymousZIndex}"
        class="header__sub-header"
      >
        <slot name="subheader" />
      </div>
    </div>
  </div>
</template>

<script>

import NavigationItem from '@/components/navbar/NavigationItem'
import { mapActions } from 'vuex'
import {
  organizationAdminPanel,
  workerPage,
  organizationRootPanel,
  officePages, shiftPages, infoPages, vacancyPages
} from '@/components/navbar/navigationValue'
import userUtil from '@/utils/user-util'
import routerList from '@/router/labels'
import IconSupport from '@/components/common/icons/IconSupport'
import IndexList from '@/components/index/IndexList'

export default {
  name: 'TheNavigation',
  components: { NavigationItem, IconSupport, IndexList },
  data () {
    return {
      windowTop: 0,
      currentId: null,
      profileItem: [
        {
          label: this.$t('header.user_option.profile'),
          event: () => {
            this.$router.push({ name: routerList.USER_CARD_NAME })
          },
          class: 'line'
        },
        {
          label: this.$t('header.user_option.support'),
          event: () => {
            this.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
          }
        },
        {
          label: this.$t('header.user_option.FAQ'),
          event: () => {
            this.$router.push({ name: routerList.FAQ_NAME })
          },
          class: 'line'
        },
        {
          label: this.$t('header.user_option.exit'),
          event: () => {
            this.logout()
          }
        }
      ]
    }
  },
  computed: {
    profileMenuItemList () {
      const vm = this
      const list = [
        {
          label: this.$t('header.user_option.profile'),
          event: () => {
            vm.$router.push({ name: routerList.USER_CARD_NAME })
          },
          class: 'line'
        },
        {
          label: this.$t('header.user_option.FAQ'),
          event: () => {
            vm.$router.push({ name: routerList.FAQ_NAME })
          },
          class: 'line'
        },
        {
          label: this.$t('header.user_option.exit'),
          event: () => {
            this.logout()
          }
        }
      ]
      if (this.officeExists || this.isOrganizationRoot) {
        const menu = [...list]
        menu[1] = {
          label: this.$t('header.user_option.support'),
          event: () => {
            vm.$router.push(this.supportLink)
          }
        }
        return menu
      }
      return list
    },
    getAnonymousZIndex () {
      // if (!(this.isOrganizationRoot && this.organization && !this.organization.isActive)) {
      //   return -10
      // }
      // return this.currentId ? -10 : 1
      return 0
    },
    navigationPanel () {
      let menu = []
      if (this.isManager) {
        return [
          ...officePages(this, this.getOfficeId, this.userChat),
          ...shiftPages(this, this.getOfficeId, this.userChat),
          ...workerPage(this, this.getOfficeId, this.userChat),
          ...vacancyPages(this, this.getOfficeId),
          ...this.getInfoData,
        ]
      } else if (this.isOrganizationRoot) {
        // const office = userUtil.rootOfficeExists(this.currentUser)
        // if (office) {
        menu = [
          ...officePages(this, this.getOfficeId, this.userChat),
          ...shiftPages(this, this.getOfficeId, this.userChat)
        ]
        return [...menu, ...organizationRootPanel, ...this.getInfoData]
      } else if (this.isOrganizationAdmin) {
        menu = [
          ...officePages(this, this.getOfficeId, this.userChat),
          ...shiftPages(this, this.getOfficeId, this.userChat)
        ]
        return [...menu, ...organizationAdminPanel, ...this.getInfoData]
      }

      return []
    },

    adminSubPanel () {
      return []
    },

    isScrolled () {
      return this.$store.getters['headerModule/isScrolled']
    },
    getInfoData () {
      const infoList = infoPages(this, this.getOfficeId)
      if (!Array.isArray(infoList) || !infoList.length) {
        return []
      }

      const info = infoList[0]
      // const office = userUtil.rootOfficeExists(this.currentUser)
      if (this.isOrganizationRoot) {
        // if (office) {
        return infoList
        // } else {
        //   return [
        //     {
        //       ...info,
        //       children: info.children.filter((item) => item.rootOnly)
        //     }
        //   ]
        // }
      } else {
        return [
          {
            ...info,
            children: info.children.filter((item) => !item.rootOnly)
          }
        ]
      }
    },
    checkScroll () {
      return this.windowTop > 10
    },
    currentUser () {
      return this.$store.state.users.info
    },
    userChat () {
      if (!this.currentUser || !this.currentUser) {
        return this.$store.state.users.chatListStatus
      }
      return this.currentUser.chatListStatus
    },
    organization () {
      return this.$store.state.organization.organizationUser
    },
    isOrganizationRoot () {
      return this.currentUser && this.currentUser.id && userUtil.isRoot(this.currentUser)
    },
    isOrganizationAdmin () {
      return this.currentUser && this.currentUser.id && userUtil.isAdmin(this.currentUser)
    },
    getOfficeId () {
      const office = this.$store.state.office
      let id = office && office.userOffice && !!office.userOffice.id ? office.userOffice.id : null
      if (this.isOrganizationRoot) {
        const localeId = userUtil.rootOfficeExists() ? userUtil.getRootCurrentOffice() : ''
        id = id || localeId
      }

      return id
    },
    currentOffice () {
      return this.$store.state.office
    },
    officeExists () {
      const user = this.$store.state.users.info
      const office = this.$store.state.office
      return (
        (office && user) &&
          (office.userOffice && office.userOffice.id)
      )
    },
    supportLink () {
      return this.officeExists
        ? { name: routerList.USER_SUPPORT_ROUTE_NAME, params: { id: this.$store.state.office.userOffice.id } }
        : { name: routerList.USER_SUPPORT_ROUTE_NAME, params: { id: null } }
    },
    isManager () {
      const user = this.$store.state.users.info
      return (!!this.officeExists && !!user.roleList.includes('ROLE_ORGANIZATION_MANAGER'))
    },
  },

  watch: {
    checkScroll (val) {
      this.$emit('isScroll', val)
    }
  },

  mounted () {
    document.querySelector('.wb-main-wrapper').addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    document.querySelector('.wb-main-wrapper').removeEventListener('scroll', this.onScroll)
  },

  methods: {
    ...mapActions('users', [
      'logout'
    ]),
    openAnonymousModal () {
      this.$store.dispatch('popup/showModal', { key: 'showAnonymousStatusModal' })
    },
    updateCurrentId (currentId) {
      this.currentId = currentId
    },
    onScroll (e) {
      this.windowTop = e.target.scrollTop
      this.$emit('scroll', { windowsScroll: this.windowTop, heightHeader: this.$el.offsetHeight })
    },
  }
}
</script>


<style lang="sass" scoped>
  .dot
    display: flex
    justify-content: center
    align-items: center
    width: 12px
    height: 13px
    background: #8BD118
    position: absolute
    border-radius: 50%
    right: -9px
    top: -7px
    font-size: 9px
    text-align: center
    color: white
  .admin-navigation__sub
    display: flex
    margin-bottom: 25px

  .header-container
    display: flex
    flex-direction: column
    // responsive hide
    width: 1024px

    // responsive show
    //width: 100%
    //max-width: 1024px
    margin: 0 auto

  .header
    position: sticky
    top: 0
    left: 0
    display: flex
    flex-direction: column
    padding: 0
    transition: .3s box-shadow
    z-index: 1000
    background-color: #F0F0F1

    &__main
      display: flex
      align-items: center
    &_scrolled
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15)

  .logo
    @extend %header-text
    display: flex
    align-items: center
    color: $main-green
    cursor: pointer
    user-select: none

    .main-title
      color: $main-green

  .navigation
    margin-left: 50px
    display: flex
    align-items: center

  .notifications
    display: flex
    align-items: center
    margin-left: auto

  .notifications-item
    padding: 0
    display: flex
    align-items: flex-end
    margin: 0px 5px

  .user-info__option
    @include fontStyle(800, 14px)
    color: rgba(0, 0, 0, 0.51)
    transition: 0.5s all
    display: none
    top: 80%
    right: 30%
    position: absolute
    opacity: 0
    min-width: 215px
    width: 100%
    background: #F0F0F1
    border: 1.5px solid rgba(0, 0, 0, 0.1)
    box-sizing: border-box
    box-shadow: 0px 10px 15px rgba(117, 117, 117, 0.1)
    border-radius: 6px
  .option__item
    padding: 15px

  .line
    border-bottom: $default_border_bottom

  .user-info__icon
    position: relative
    cursor: pointer
    margin-bottom: -10px
    &:hover .user-info__option
      display: block
      opacity: 1
      z-index: 99999999 !important

  .navigation-item-menu-name
    font-family: Montserrat
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    color: #000000
    opacity: 0.5 !important
    margin: 10px 0px
    padding: 5px 0px

  .activeMenuItem
    opacity: 1 !important

  .anonymous-banner
    min-height: 30px
    left: 669px
    font-style: normal
    font-weight: 800
    font-size: 1.2em
    line-height: 15px
    color: #FF0000
    opacity: 0.9
    cursor: pointer

</style>
